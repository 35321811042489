.calendarRectangle {
    width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 0.9rem;
    overflow: visible;
}
.calendarRow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.mainCalendar {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}
.mainCalendar .calendarHeader {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    color: white;
}
.mainCalendar .calendarHeader .titleHeader {
    width: 100%;
    height: 70%;
    white-space: nowrap;
    font-size: 1.2em;
    background-color: transparent;
}
.mainCalendar .calendarHeader .titleHeader .headerText {
    flex: 5;
    display: flex;
}
.mainCalendar .calendarHeader .titleHeader .headerText .todayLabel {
    flex: 1;
    font-size: 0.8em;
}
.mainCalendar .calendarHeader .titleHeader .headerText .monthLabel {
    flex: 3;
    color: white;
    font-size: 0.9rem;
}
.mainCalendar .calendarHeader .daysHeader {
    width: 100%;
    padding: 5px 0;
    font-size: 0.8em;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}
.mainCalendar .calendarHeader .daysHeader .dayName {
    color: white;
}
.mainCalendar .calendarHeader .buttonContainer {
    width: 100%;
    height: 30%;
}
.mainCalendar .calendarHeader .buttonContainer .eventButton {
    flex-grow: 1;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.mainCalendar .daysContainer {
    width: 100%;
    background: transparent;
    height: inherit;
    padding: 15px 0;
}
.mainCalendar .daysContainer .week {
    height: 15%;
}
.calendarItemContainer {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    transition: all 0.4s ease-in-out 0s;
}
.calendarItem-container.arrow {
    cursor: pointer;
    font-size: 0.9rem;
    color: #d6dee5;
}
.day {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 30px;
    width: 30px;
}
.day .dayNumber {
    width: 28px;
    height: 28px;
    cursor: pointer;
    display: flex;
    margin: 3px 0;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.day.hasEvents .dayNumber {
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    font-weight: bold;
}
.day.today {
    font-weight: bold;
    color: rgba(111, 89, 246, 1);
}
.day.differentMonth {
    visibility: hidden;
    height: 0;
}
.day.selected.hasEvents {
    position: relative;
}
.calendarDot {
    position: absolute;
    bottom: 1px;
    right: 1px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: inherit;
}

.dayCancelled {
    background-color: rgb(255, 0, 0, 0.5) !important;
}

.calendar__navigation__prev-button, .calendar__navigation__next-button {
	background-color: #7551ff !important;
    padding: 4px;
    border-radius: 10px;
}